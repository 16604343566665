.calculate {
  background: var(--color-background-double) !important;
  border: 1px solid rgba(18, 18, 18, 0.05);
  box-shadow: 0px 18px 64px rgba(215, 228, 249, 0.15);
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  margin-top: 72px;
  height: 600px;
  padding: 0 48px;
  margin-bottom: 48px;
  max-width: 1280px;
  align-items: center;
  justify-content: center;
  &__block-text {
    flex: 1;
    margin-right: 32px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 86px;
      line-height: 114.68%;
      color: var(--color-text);
      span {
        color: var(--color-text-double);
      }
    }
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 140%;
      color: var(--color-text);
    }
  }
  &__block-calc {
    max-width: 450px;
    background: #ffffff;
    border: 1px solid rgba(18, 18, 18, 0.1);
    box-shadow: 0px 18px 64px rgba(215, 228, 249, 0.15);
    border-radius: 18px;
    margin-top: -24px;
    margin-bottom: -24px;
    padding: 30px 32px;
    &__sum {
      display: flex;
      flex-direction: row;
      margin-top: 32px;
      > div {
        padding-right: 32px;
      }
      div {
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: var(--color-dark-blue);
        }
        p {
          font-style: normal;
          font-weight: 800;
          font-size: 14px;
          line-height: 19px;
          color: var(--color-dark-blue);
        }
      }
    }
    &__button {
      padding-top: 26px;
      button {
        width: 100%;
        background: var(--color-button-background-dark);
        border: 2px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 3px 0px rgba(245, 157, 66, 0.15);
        border-radius: 72px;
        padding: 10px 16px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: white;
        height: 64px;
        cursor: pointer;
      }
    }
    &__text {
      padding-top: 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-right: 32px;
      color: #ccd2e3;
    }
    &__loan {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eef1f3;
      padding-bottom: 32px;
      &__title {
        display: flex;
        flex-direction: row;
        div:first-child {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: var(--color-dark-blue);
        }
        div:last-child {
          margin-left: auto;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          text-align: right;
          span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            text-align: right;
            color: #ccd2e3;
            padding-left: 2px;
          }
        }
      }
      &__slider {
        margin-top: 24px;
      }
      &__sum {
        display: flex;
        flex-direction: row;
        margin-top: -10px;
        div:last-child {
          margin-left: auto;
        }
        div {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #939aa9;
        }
      }
    }
  }
}

.button {
  &__white-theme {
    border: 1px solid var(--color-button-background-dark);
    border-radius: 62px;
    color: var(--color-button-background-dark);
    padding: 15px 24px;
    background-color: var(--color-button-text-dark);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    > img {
      padding-right: 2px;
    }
  }
  &__dark-theme {
    background: var(--color-button-background-dark);
    border-radius: 62px;
    color: var(--color-button-text-dark);
    padding: 15px 24px;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    > img {
      padding-right: 2px;
    }
  }
  &__yellow-theme {
    background: linear-gradient(180deg, #f4bc40 0%, #e8b138 100%), #020202;
    border: 2px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 3px 0px rgba(245, 157, 66, 0.15);
    border-radius: 62px;
    color: var(--color-button-text-dark);
    padding: 15px 24px;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    > img {
      padding-right: 2px;
    }
  }
  &__gray-theme {
    background: #f3f3f3;
    border: 2px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 3px 0px rgba(245, 157, 66, 0.15);
    border-radius: 62px;
    color: black;
    padding: 15px 24px;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    > img {
      padding-right: 2px;
    }
  }
  &__white-theme:disabled {
    opacity: 0.5;
  }
  &__dark-theme:disabled {
    opacity: 0.5;
  }
  &__yellow-theme:disabled {
    opacity: 0.5;
  }
  &__gray-theme:disabled {
    opacity: 0.5;
  }
}

.slider__root {
  color: #ccd2e3 !important;
  height: 4px !important;
}

.MuiSlider-thumb {
  background-color: white !important;
  border: 4px solid #f4bc40;
  width: 28px !important;
  height: 28px !important;
}

.accordion__title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: var(--color-dark-blue) !important;
}

.MuiAccordion-root::before {
  display: none !important;
}

.select-data {
  display: flex;
  flex-direction: row;
  > div {
    flex: 1;
    padding: 5px;
  }
  &__day {
    // padding-top: 23px !important;
    .MuiSelect-select {
      padding-top: 5px;
    }
  }
  &__month {
    // padding-top: 23px !important;
    .MuiSelect-select {
      padding-top: 5px;
    }
  }
  &__years {
    // padding-top: 23px !important;
    .MuiSelect-select {
      padding-top: 5px;
    }
  }
}
.text-errors {
  position: relative;
  top: -25px;
  left: 14px;
  color: #d32f2f;
  font-family: 'Roboto', 'Helvetica', 'Arial' sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
}
.custom-alert {
  position: fixed;
  bottom: 35px;
  max-width: 350px;
  right: 20px;
  margin-left: 20px;
  box-shadow: 0px 18px 64px rgb(215 228 249 / 15%);
  border: 1px solid var(--color-text-double);
  border-radius: 18px;
  margin-top: -24px;
  margin-bottom: -24px;
  padding: 15px 32px;
  padding-top: 25px;
  background-color: white;
  z-index: 1000;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  .tg-icon {
    bottom: 70px;
  }
  &__img {
    height: 30px;
  }
  &__text {
    padding-left: 15px;
    color: var(--color-dark-text);
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 5px;
  }
}
.custom-alert:hover {
  border: 1px solid var(--color-text-double);
  box-shadow: 0px 9px 32px var(--color-text-double);
}
@media (max-width: 992px) {
  .custom-block-header {
    background: transparent !important;
  }
  .calculate {
    flex-direction: column;
    height: auto;
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 15px;
    margin-bottom: 24px;
    &__block-calc {
      padding: 0;
      padding-top: 16px;
      background: transparent;
      border: none;
      margin-bottom: 16px;
      &__loan {
        margin-bottom: 16px !important;
        padding-bottom: 16px;
        &__slider {
          margin-top: 0px;
        }
      }
      &__sum {
        margin-top: 16px;
      }
      &__button {
      }
    }
    &__block-text {
      h1 {
        font-weight: 500;
        font-size: 24px !important;
        line-height: 114.68%;
        display: flex;
        align-items: center;
        color: #020202;
        span {
          padding-left: 15px;
        }
      }
      h3 {
        font-size: 15px;
      }
    }
  }
}
