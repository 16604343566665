:root {
  --color-primary: #1688da;
  --color-dark-text: #273454;
  --color-background: #ffffff;
  --color-button-green: #5db967;
  --color-button-dark-blue: #273454;
  --color-border: #eef1f3;
  --color-background-double: #fafafa;
  --color-text: #020202;
  --color-text-double: #2471a3;
  --color-button-background-dark: #2471a3;
  --color-button-text-dark: white;
}
