.custom-slider {
  width: calc(100% - 10px);
}
.card-about {
  // background: #ffffff;
  // box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
  // border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  &__image {
    width: 100%;
    height: 100%;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 124.18%;
    color: var(--color-dark-text);
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    padding-top: 34px;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--color-dark-text);
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
}

.article {
  background: rgba(247, 248, 253, 0.01);
  border: 1px solid #e8e8ea;
  border-radius: 18px;
  padding: 14px 17px;
  cursor: pointer;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 124.18%;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    padding-bottom: 18px;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(2, 2, 2, 0.75);
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-bottom: 18px;
  }
  &__date {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 124.18%;
    text-align: left;
    color: #020202;
    // text-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.1);
  }
}
.slider-about {
  display: none;
}
@media (max-width: 996px) {
  .articles {
    padding-bottom: 16px !important;
    &__title {
      padding-bottom: 32px !important;
    }
  }
  .articles__cards {
    margin-left: 15px;
    margin-right: 15px;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .slider-about {
    display: block;
    margin-bottom: 42px;
    margin-top: 32px;
    // margin-left: 16px;
    // margin-right: 16px;
  }
  .card-about {
    margin-right: 15px;
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.25);
    border-radius: 18px;
  }
  .slick-slider {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .slick-list {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .container-card {
    padding: 30px 0;
  }
}

.custom-slider {
  &__block-slider {
    margin-right: 15px;
    border: 1px solid rgba(18, 18, 18, 0.05);
    box-shadow: 0px 18px 52.8537px rgb(215 228 249 / 25%);
    border-radius: 18px;
    padding: 32px;
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 124.18%;
      color: var(--color-dark-text);
      padding-top: 34px;
    }
  }
}
